// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  text-decoration: none;
  @include transition(all, 100ms, linear);

  @include on-event {
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }
  .article-body-container & {
    color: $primary-color;
    text-decoration: none;
    @include transition(all, .25s, ease);

    @include on-event {
      text-decoration: underline;
    }
  }
}

body {
  color: $color-black;
  font: 14px/1.5 $font;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased
}

code {
  font-family: 'Source Code Pro', Inconsolata, serif;
}
