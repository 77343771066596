// -----------------------------------------------------------------------------
// This file contains all styles related to the standard gallery-slide component.
// -----------------------------------------------------------------------------
.quiz-progress {
  border: 1px solid #CCC;
    background: #FAFAFA;
    height: 21px;

    .quiz-progress-inner {
      background: #1FBF6F;
      height: 19px;
      font-size: 13px;
      font-weight: 800;
      color: white;
      padding-left: 10px;
    }
}
.quiz-bottom {
  margin-bottom:15px;
}
.correctHeadline {
  font-size: 20px;
  font-weight: 800;
  color: #1FBF6F;
}
.wrongHeadline {
  font-size: 20px;
  font-weight: 800;
  color: red;
}
.quiz-caption {
  padding: 20px;
    border: 1px solid #CCC;
    margin-top: 20px;
    font-size: 15px;
    line-height: 25px;
}
.gallery-slide {
  > * {
    margin-bottom: 26px;
  }
  h2 {
    @include mq($until:tablet) {
      text-align: center
    }
  }
  &__number {
    // display: inline-block;
    background-color: $text-color;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 22px;
    padding: 8px 12px;
    float: left;
    margin-right:15px;

  }
  h2 &__title {
    font: 700 26px/30px $heading-font-stack;
    margin-bottom: 8px;
  }
  &__image {
    text-align: center;
    img {
      max-width: 100%;
      max-height: 600px;
    }
    &.imgLink{
      cursor: pointer;
    }
  }
  &__description {
    @extend %body-text;
  }
}

.gallery-bottom, .gallery-top {
  display:flex;
  width: 100%;
  .btn-next {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
    flex-basis: 100%;
  }
  .btn-previous {
    margin-right: 2%;
    flex-shrink:1;
    flex-grow: 1;
    width: 48%;
  }
}
