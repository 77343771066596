// -----------------------------------------------------------------------------
// This file contains styles that are specific to the 404 page.
// -----------------------------------------------------------------------------
section.error-page {
  text-align: center;
  font-family: $heading-font-stack;
  margin-bottom: 70px;
  margin-top: 50px;

  h1 {
    margin: 0 0 17px;
    font-weight: 400;
  }
  .sub-headline {
    font-size: 21px;
    line-height: 24px;
    margin: 0 0 40px;
    color: #a6a6a6;
    font-weight: 500;
    font-family: $heading-font-stack;
  }
  .home-redirect {
    font-family: $text-font-stack;
    font-size: 14px;

    a {
      display: inline-block;
      color: #FFFFFF;
      background: $color-black;
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      padding: 8px 15px;
      text-transform: uppercase;
      margin-left: 8px;

      @include transition(all, .25s, ease)
      &:hover {
        background: $primary-color;
      }
    }
  }
}

