.fb-combo-bar {
  display: flex;
  padding: 5px;
  height: 60px;
  flex-wrap: nowrap;
  border-radius: 5px;
  align-items: center;
  background-color: #516eab;
  margin-bottom: 1em;
  &__like {
    width: 200px;
    height: 50px;
    color: #516eab;
    font-size: 17.5px;
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  &__share {
    text-align: center;
    flex: 1;
    a {
      color: #FFFFFF !important;
      transition: all .5 linear;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: transparentize(#FFFFFF, .25);
      }
    }
  }
}
