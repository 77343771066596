// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar of the site/application.
// -----------------------------------------------------------------------------

.main-sidebar {
  flex: 0 0 auto;
  margin: 0 auto;
  max-width: 350px;
  min-width: 350px;


  @include mq($until:desktopAd) {
    max-width: 100%;
  }
  > * {
    margin: 0 auto;
  }
  @include mq($from:desktopAd, $until:desktopLg) {
    display: none;
  }
  @include mq($from:desktopLg) {
    padding-left: 24px;
  }
  .about_text {
    margin-top: 10px;
    padding: 15px;
    background-color: #efefef;
    width: 100%;
    p {
      font-size: 75%;
      // margin-top: 15px;
    }
  }
}
.left-sidebar {
  display: none;
  // min-width:320px;
  &:not(empty) {
    flex: 0 1 325px;
  }
  @include mq($from:desktopXLg) {
    display:block;
    margin-right:10px;
    // min-width:170px;
  }
}
.basic-card {
  margin: 16px auto;

  &__image {
    min-height: 190px;
    max-width: 100%;
    margin-bottom: 16px;
    // background-image: url(https://d1qcpsucua33sk.cloudfront.net/redspark/953f8e50-e0e7-4521-b444-79eb6ab4d0bb);

    &:hover {
      cursor: pointer;
    }
    @include mq($from:mobileLandscape) {
      min-height: 260px;
    }
    @include mq($from:desktop) {
      min-height: 160px;
    }
  }
}


.sidebar-headline, .bottombar-headline {
  // color: white;
  // text-transform: uppercase;
  flex: 0 1 auto;
  width: 100%;
  font-weight: bold;
  padding: 5px 0px;
  // @include mq($until:tablet) {
  //   width:100%;
  // }
  //transform: skewX(-10deg);
  // margin-bottom:10px;
}

.bottombar-headline {
  margin-left: 1%;
  margin-right: 1%;
}