// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-header {
  // position: fixed;
  min-width: 100%;
  font-family: $menu-font-stack;
  // background-color: #FFFFFF;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, .26);
  // z-index: 99;
  
  @include mq($from: desktopAd) {
    background-color: hsla(0,0%,100%,.95);
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
  .wrapper, .nav {
    display: flex;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 2%;
    position: relative;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
#menu-toggle {
  display: none;

  &:checked ~ .nav {
    opacity: 1;
    height: 100vh;
    visibility: visible;
    position: sticky;
  }
}
.label-toggle {
  display: block;
  cursor: pointer;
  height: 48px;
  width: 48px;
  text-align: center;
  z-index: 2;
  margin-left: auto;

  > i {
    line-height: 2;
  }
}
.site-nav__logo {

  &-link {
    // height: 48px;
    display: flex;
    align-items: center;
    padding: .15rem .75rem;
  }
  &-image {
    max-height: 2.25rem;
    height: auto;
    max-width: 100%;
  }
}
.nav {
  flex-direction: column;
  flex: 0 0 100%;
  height: 0;
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  background: #FFFFFF;
  // @include transition(all, .5s, ease);
  // @include transition(opacity, .3s, ease);

  &-link {
    display: block;
    color: $secondary-color;
    text-align: center;
    padding: 1.5em 0;
    border-bottom: 2px solid #ebecec;
    transition: none;
  }
  &-item {
    display: inline-block;
  }
}
@include mq($from:desktopAd) {
  .main-header {
    margin-top: 20px;
    // margin-bottom: 70px;
  }
  .main-header .wrapper {
    // height: 60px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
  }
  .site-nav__logo {
    margin: 0;
    max-width: calc(20% + 48px);
    // height: 95%;

    &-link {
      margin-left: 0;
      // height: 60px;
    }
    &-image {
      max-height: 100%;
    }
  }
  #menu-toggle, .label-toggle {
    display: none;
  }
  .nav {
    flex-direction: row;
    justify-content: space-around;
    flex: .75 1 auto;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
    opacity: 1;
    visibility: visible;

    &-link {
      padding: 0;
      margin: 18px 0 .25em;
      border: none;
      flex: 0 1 auto;
      position: relative;
      border-bottom: 5px solid transparent;
      text-transform: uppercase;
      font-size: 20px;

      &:hover, &.active {
        border-bottom: 5px solid $primary-color;
      }
      @include mq($from:desktopLg) {
        font-size: 20px;
        padding: 0 5px;
      }
    }
    &-item {
      position: relative;
      display: block;
      padding-bottom: 1px;
    }
  }
}
