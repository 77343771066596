// -----------------------------------------------------------------------------
// This is for all Facebook gallery specific styling.
// -----------------------------------------------------------------------------

.gallery-page__facebook {

  @include mq($until:tablet) {
    .gallery-main {
      > .gallery-main__btn {
        display: none;
      }
    }
    .gallery-slide__number {
      display: none;
    }
    .fb-combo-bar {
      display: none;
    }
    .fb-comments-widget {
      display: none;
    }
  }

  @include mq($until:desktop) {
    .fb-page-embed {
      display: none;
    }
  }

  @include mq($from: tablet) {
    .main-header {
      .site-nav__logo {
        width: auto;
      }
    }
  }
  @include mq($from: desktop) {
    .main-header {
      .site-nav__logo {
        // margin-top: 0;
      }
    }
  }
}