.ad-placement {
  // background-color: transparentize(#66D7D1, .5);
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #1c1c26;
  font-size: 14px;
  header & {
    max-width: 728px;
    min-height: 90px;
    margin: 0 auto;
    @include mq($until: desktopLg) { display: none; }
  }

  &.gm-sticky.scrolled {
    position: fixed;
    top: 10px;
    // left:10px;
    width: 1px;
    z-index: 10000;
  }
}
.ad-disclaimer, .aatm-disclaimer {
    font-size: 8.5px;
    color: #BBB;
    display: blockz;
    line-height: 21px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
