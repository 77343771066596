// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  padding: 16px;
  background-color: $text-color;
  color: #FFFFFF;
  font-size: 12px;
  margin-top: 20px;

  a {
    color:$primary-color;
  }
}
