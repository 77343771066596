// -----------------------------------------------------------------------------
// This is for all ARB gallery specific styling.
// -----------------------------------------------------------------------------

body.gallery-page__arb {
  .main-sidebar {
    padding-left: 0px;

    .basic-card {
      display: none;
    }
  }
  @include mq($from:tablet) {
    .gallery-main {
      padding: 0px 24px;
    }
  }
  @include mq($from:desktopAd, $until:desktopLg) {
    .site-nav__logo {
      img {
        margin: 0 auto;
        height: 35px;
      }
    }
    .site-nav__logo {
      height: auto;
      width: 100%;
    }
    .nav {
      max-width: 55%;
      font-weight: 500;
    }
  }
  @include mq($from:desktopAd) {
  }
}