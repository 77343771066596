// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus,
    &.active {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus,
    &.active {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/*------------------------------------*\
    #MIXINS
\*------------------------------------*/

/** CSS Transition
 *  Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

/** Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback
 *  Usage: @include font-size(1, large);
 */
@mixin font-size( $decimal-size, $keyword: null ) {
  @if $keyword{ font-size: $keyword; }
  @else { font-size: $decimal-size * $base-font-multiplier * 16px;}
  font-size: $decimal-size * 1rem;
}

@mixin bp($point) {
    @if $point == small-mobile-up {
        @media all and (min-width:$breakpoint--small-mobile) {
            @content;
        }
    }
    @else if $point == mobile-up {
        @media all and (min-width:$breakpoint--mobile) {
            @content;
        }
    }
    @else if $point == large-mobile-up {
        @media all and (min-width:$breakpoint--large-mobile) {
            @content;
        }
    }
    @else if $point == mobile-landscape-down {
        @media all and (max-width:$breakpoint--mobile-landscape) {
            @content;
        }
    }
    @else if $point == mobile-landscape-up {
        @media all and (min-width:$breakpoint--mobile-landscape) {
            @content;
        }
    }
    @else if $point == tablet-up {
        @media all and (min-width:$breakpoint--tablet) {
            @content;
        }
    }
    @else if $point == tablet-down {
        @media all and (max-width:$breakpoint--tablet) {
            @content;
        }
    }
    @else if $point == tablet-landscape-up {
        @media all and (min-width:$breakpoint--tablet-landscape) {
            @content;
        }
    }
    @else if $point == desktop-up {
        @media all and (min-width:$breakpoint--desktop) {
            @content;
        }
    }
    @else if $point == $point {
        @media all and (min-width:$point) {
            @content;
        }
    }
}

@each $bp, $value in $mq-breakpoints {
    .show-on-#{$bp} {
        @include mq($until: #{$bp}) {
            display: none;
        }
    }
    .dont-show-on-#{$bp} {
        @include mq(#{$bp}) {
            display: none;
        }
    }
}

@mixin lhCrop($line-height) {
    &::before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
  }
  