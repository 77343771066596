/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}

%body-text {
  font: normal 15px/26px $text-font-stack;
}