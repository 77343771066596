ul.pagination {
  margin: 30px 0;
  font-size: 12px;
  display: inline-block;
  width: 60%;
  font-family: "Open Sans", Arial, sans-serif;

  a {
    padding: 5px 11px;
    border: 1px solid #e3e3e3;
    display: inline-block;
    margin: 0 8px 8px 0;
    min-width: 33px;
    text-align: center;
    color: #666;
    line-height: 21px;
    float: left;
  }
  a:hover {
    color: #fff;
    background-color: #444;
    border-color: #444;
  }
  li.active a {
    border-color: $primary-color;
    background-color: $primary-color;
    color: #fff;
  }
}
.page-count {
  float: right;
  margin: 0;
  border: none;
  padding: 6px 0 6px 6px;
  min-width: 33px;
  text-align: center;
  color: #666;
  line-height: 21px;
  display: inline-block;
  font-size: 12px;
  font-family: "Open Sans", Arial, sans-serif;
  color: #666;
  margin: 30px 0;
}