.article-meta-info {
  display: flex;
  color: #444;
  align-items: center;
  width: 100%;
  font-size:11px;
  line-height: 11px;
  font: 11px/11px "Open Sans", Arial, sans-serif;
  margin: 12px 0 24px;

  > *:not(:first-child) {
    margin-left: 18px;
  }

  &__tag {
    display: block;
    background: $text-color;
    color: #FFFFFF !important;
    padding: 3px 6px;
    font-size: 10px;
    @include transition(all, .5s, ease)
    &:hover {
      background: #444444;
      text-decoration: none;
      color: #FFFFFF !important;
    }
  }
  &__published-date {
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: 13px;
    i {
      margin-right: .25rem;
      font-size: 12px;
    }
  }
}
