.category-card {
  display: flex;
  min-height: 75px;
  margin: 16px 0;

  &__image {
    flex: 0 0 25%;
    height: auto;
    margin-right: 8px;
  }
  &__content {
    h3 {
      font-size: 15px;
    }
    p, span {
      display: none;
    }
  }
}
@include mq($from:mobileLandscape) {
  .category-card {
    min-height: 175px;

    &__image {
      flex: 1 0 40%;
      margin-right: 18px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 16px;
      }
    }
  }
}
@include mq($from:tablet) {
  .category-card {
    border-bottom: 1px dashed #eaeaea;
    margin: 24px auto;

    &__image {
      max-height: 145px;
      width: 192px;
      flex-basis: 192px;
    }
    &__content {
      h3 {
        font-size: 20px;
        line-height: 23px;
      }
      p {
        display: block;
        font-size: 14px;
        color: #777;
        line-height: 21px;
        margin-bottom: 16px;
      }
    }
  }
}
@include mq($from:desktop) {
  .category-card {
    border-bottom: none;
    max-width: 700px;
    margin-bottom: 50px;

    &__image {
      flex-basis: 300px;
      min-height: 235px;
    }
    &__content {
      h3 {
        font-size: 25px;
        line-height: 29px;
      }
    }
    .read-more-btn {
      display: inline-block;
      background-color: $primary-color;
      color: #FFFFFF;
      padding: 8px 16px;
      font-size: 12px;
      margin-top: auto;
    }
  }
}
@include mq($from:desktopLg) {
  .category-card {
    max-width: 95%;
    margin: 24px 0 50px;

    &__image {
      flex-basis: 325px;
    }
  }
}