/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-stack;
}
/**
 * Heading 1
 */
h1  {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
}

/**
 * Heading 2
 */
h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.2;
}

/**
 * Heading 3
 */
h3  {
  line-height: 1.2;
}

/**
 * Heading 4
 */
h4  {

}

/**
 * Heading 5
 */
h5 {

}

/**
 * Heading 6
 */
h6 {

}

/**
 * Subheading
 */
.subheading {
  font-family: $font-secondary;
  font-weight: normal;
}
