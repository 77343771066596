// -----------------------------------------------------------------------------
// This file contains all styles related to the main content of the site/application.
// -----------------------------------------------------------------------------

#static-content {
  max-width:800px;
  margin:0 auto;
  margin-top:40px;

  h3 {
    margin-bottom:20px;
    font-weight:800;
    font-size:30px
  }
  h4 {
    margin-bottom:10px;
  }
  p {
    margin-bottom:20px;
    font-size:14px;
  }
   em {
     font-style:italic;
   }
   .address {
     white-space: pre;
     font-size:14px;
     margin-bottom:20px;
   }
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.content-wrapper {
  flex: 1 1 auto;
  // margin-top: 80px;

  @include mq($from:tablet) {
    margin-left: auto;
    margin-right: auto;
  }
}

.primary-content, .main-bottombar, .main-sidebar, .main-abandonbar {
  h3 {
    font-size:80%;
  }
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 8px;
  max-width: 1200px;
	margin: 0 auto;
  @include mq($from:tablet) {
    display: flex;
  }
  @include mq($from:desktopLg) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    // justify-content: flex-start;
    padding: 0px;
  }
  & .basic-card {
      @include mq($until:tablet) {
        vertical-align: top;
        width:100% !important;
        flex: 1 1 100% !important;
        .basic-card__image {
          max-height: 150px;
          min-height: 150px;
        }
        &.mobile-cols-2 {
          width:40% !important;
          flex: 1 1 40% !important;
          margin-left: 3% !important;
          margin-right: 3% !important;
          .basic-card__image {
            max-height: 120px;
            min-height: 120px;
          }
        }
        &.mobile-cols-3 {
          width:25% !important;
          flex: 1 1 25% !important;
          margin-left: 3% !important;
          margin-right: 3% !important;
          .basic-card__image {
            max-height: 100px;
            min-height: 100px;
          }
        }
        h3 {
          margin-top: 10px;
          font-size: 70%;
          line-height: 20px;
          word-wrap: break-word;
          font-weight: 600;
        }
        
      }
    }
}

.main-bottombar, .main-sidebar, .main-abandonbar  {
  width:100%;
  color: black;
  // max-width: 740px;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: break-word;
  .list {
    // display: flex;
    // height: 100%;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // flex: 1 1 100%;
    width:100%;
    & .basic-card {
      vertical-align: top;
      a.list {
        color:black;
      }
    }
  }
  
}
.main-sidebar, .main-abandonbar {
  // height: 100%;
  // flex: 0 0 325px;
}

.category-main {
//  flex: 1 1 75%;
  padding: $inner-content-padding;

  @include mq($from:tablet, $until:desktopLg) {
    max-width: 85%;
    margin: 0 auto;
  }
  @include mq($from:desktopLg) {
    //flex: 1 1 1100px;
  }
}

.gallery-main, article {
  //  flex: 0 1 728px;
  max-width: 740px;
}

.twitter-tweet {
  margin-left: auto;
  margin-right: auto;
}
