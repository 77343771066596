@keyframes slide-down {
  0% {
    opacity: 1; }
  90% {
    opacity: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes popin {
  0% {
    transform: scale(0);
    opacity: 0; }
  85% {
    transform: scale(1.05);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

#ouibounce-modal {
    text-align:center;
    position:fixed;
    width:100%;
    height:100%;
    background: rgba(68, 68, 68, 0.5);
    z-index: 999999999;
    .modal-row {
        width: 100%;
        display:inline-block;
        flex: 0 0 auto;
        .modal-middle-first, .modal-middle-second, .modal-middle-third {
            display:inline-block;
            flex: 0 0 30%;
            flex-wrap: wrap;
        }
    }
}

.modal-underlay, .modal-wrapper {
  position: absolute;
  top: 5%;
  left: 5%;
  height: 90%;
  width: 90%;
  background: white;
    box-shadow: 10px 5px 50px #999;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 15px; }

.modal-underlay {
  z-index: 99;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s; }
  .modal-underlay.blue {
    background-color: #266990;
    opacity: 0.925;
    filter: alpha(opacity=92.5); }

.modal-wrapper {
  font-family: "acumin-pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  z-index: 101;
  display: block;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
      animation: popin .3s;
  -webkit-animation: popin .3s; }

.modal-cta {
  font-size: 1.75em;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
      justify-content: center;
  margin: 0px auto; }
  .modal-cta.hand {
    max-width: 95%; }
  .modal-cta > div {
    min-width: 0; }
  @media only screen and (min-width: 450px) {
    .modal-cta {
      font-size: 2em; } }
  @media only screen and (min-width: 780px) {
    .modal-cta {
      font-size: 2.75em; } }
  .modal-cta .hand-svg {
    background-image: url('/images/money-hand-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 105% 20px;
    width: 100px;
    height: 100px; }
    @media only screen and (min-width: 780px) {
      .modal-cta .hand-svg {
        width: 150px;
        height: 150px; } }
  .modal-cta .modal-cta-text {
    align-self: flex-end;
    line-height: 2em;
    font-size: 1.25em;
    font-weight: 500; }
  .modal-cta .responsive-img {
    max-width: 150px;
    padding-right: .5em; }
    .modal-cta .responsive-img img {
      vertical-align: bottom;
      height: 85px;
      max-width: 100%; }
      @media only screen and (min-width: 780px) {
        .modal-cta .responsive-img img {
          height: 115px; } }



.modal-close {
  position: absolute;
  top: 0;
  left: 0;
  text-align: right;
  padding-top: 15px;
  // margin-bottom: auto;
  // flex: 0 0 auto;
  padding-right: 15px;
  line-height: 0px;
  width: 100%;

  // -ms-flex-item-align: start;
      // align-self: flex-start;
      &:before {
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f00d";
            cursor: pointer;
        }
    }
  

.modal-overlay.light {
  background-color: #FFFFFF;
  opacity: 0.5;
  filter: alpha(opacity=50); }

