// -----------------------------------------------------------------------------
// This file contains styles that are specific to the gallery page.
// -----------------------------------------------------------------------------

.question-headline {
  margin-top:20px;
  display:block;
}
.quiz-response {
  padding:20px;
  width:100%;
  background:#F2F2F2;
  border: 1px solid #D8D8D8;
  margin-top:20px;
  margin-bottom:20px;
  .quiz-headline-small {
    font-size:11px;
    color:#666;
  }
  .quizSocialShare {
    font-size:16px;
    a {
      padding:8px;
      background:red;
      color:white;
      margin-top:10px;
      margin-right:10px;
      border-radius:3px;
      display:inline-block;
      &:before {
        font: normal normal normal 14px/1 FontAwesome;
        padding-left:12px;
      }
      &.facebook {
        background:#305c99;
        &:before {
          content: "\f09a";
        }
      }
      &.twitter {
        background:#00cdff;
        &:before {
          content: "\f099";
        }
      }
      &.google {
        background: #d24228;
        &:before {
          content: "\f0d5";
        }
      }
      &.pinterest {
        background: #bd081c;
        &:before {
          content: "\f231";
        }
      }
      span {
        margin-left:21px;
        border-left:1px solid rgba(255, 255, 255, 0.38);
        padding-left:20px;
        padding-right:10px;
      }
    }
  }
  .quiz-response-text {
    font-size:16px;
    margin-top:10px;
    margin-bottom:25px;
    line-height:25px;
    h2 {
      margin-bottom: 10px;
    }
    .quiz-response-image {
      width: 100%;
      margin-top: 15px;
    }
  }
  .i-got {
    font-weight:800;
  }
}
.quiz-page {
  .continueButton {
    &.disabled {
      background-color: #CCC;
      color: #000;
      cursor: not-allowed;
      opacity: 0.2;
      -webkit-animation: none;
      -moz-animation: none;
      animation: none;
    }
  }
}
.quiz-answer {
  position: relative;
  padding:5px;
  width:100%;
  background:#F2F2F2;
  border: 1px solid #D8D8D8;
  text-align: center;
  margin-bottom:15px;
  vertical-align: top;
  &:not(.no-box):before {
    content: '☐';
    font-size: 24px;
    color: black;
    margin: 0;
    margin-right: 10px;
  }

  &.wrong {
    background: #fd797b;
  }
  &.correct {
    background: #88f078;
    &:not(.no-box):before {
      content: '☑';
    }
  }

  &.notanswered:hover {
    background:#fff195;
    transition: background 550ms ease-out;
    cursor: pointer;
  }

  &.answered {
    cursor: default;
    &:hover {
      cursor: default;
    }
    &.disabled {
      * {
        opacity: 0.4;
        filter: alpha(opacity=40);
        zoom: 1;
      }
    }
  }

  .quiz-question-image {
    width: 100%;
  }
  span.answer {
    position: absolute;
    right: 10px;
    bottom: 10px;
    -webkit-text-stroke: 1px black;
    color: white;
    text-shadow: -1px 0 #000000,0 1px #000000,1px 0 #000000,0 -1px #000000;
    font-weight: bold;
  }
}
.gallery-main {
  max-width: 728px;
  @include mq($from:wide) {
    min-width: 728px;
  }
  margin: 0 auto;
  .intro {
    font-size: 19px;
    margin-bottom: 20px;
  }
  figcaption {
    margin-top: -30px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #999;
    text-align: center;
  }
  &__title {
    font: 700 28px/42px $heading-font-stack;
    display: none;
  }
  &__description {
    @extend %body-text;
    margin-bottom: 26px;
  }
  &__btn {
    display: block;
    background-color: $primary-color;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    font-family: $heading-font-stack;
    font-size: 20px;
    border-radius: 2px;
    margin: 5px auto;
    cursor: pointer;
    &:hover {
      background-color: $primary-color;
    }
  }
}


body.gallery-page {
  .main-header {
    position: relative;
  }
  .content-wrapper {
    margin-top: 0px;
  }

  @include mq($from:desktopAd) {
    .main-header {
      box-shadow: none;

      .wrapper {
        height: auto;
        position: relative;
        // padding: 24px;
      }
    }
    // .site-nav__logo {
    //   height: 40px;
    //   width: 100%;
    // }

    .nav {
      flex-basis: auto;
      margin: 0 auto;
      justify-content: space-around;

      &-item {
        font-size: 14px;
      }
    }
  }
  @include mq($from:desktopLg) {
    .main-header .wrapper {
      max-width: 1230px;
    }
    .site-nav__logo {
      /*height: 50px;*/
      /*width: 300px;*/
      &-link {
        // height: 120px;
        padding-left: 0;
      }
      // margin-top: 25px;

      &-link {
        background-position: left !important;
      }
    }
    .nav {
      justify-content: flex-start;
      margin: 0;
    }
    .nav-link {
      margin-right: 24px;
    }
    .nav-item {
      font-size: 18px;
    }
  }
}

// .gallery-slide {
//   margin: 16px auto;

//   &__number {
//     display: inline-block;
//     background-color: $text-color;
//     color: #FFFFFF;
//     font-weight: 700;
//     font-size: 22px;
//     padding: 8px 12px;
//   }
//   &__title {
//     font-weight: 700;
//     font-size: 22px;
//   }
//   &__image {
//     display: block;
//     margin: 24px auto;
//     text-align: center;

//     img {
//       max-height: 500px;
//       max-width: 100%;
//       margin-bottom: 0px auto;
//       width: auto;
//     }
//   }
// }
// .gallery-btn {
//   background-color: $brand-color;
//   text-align: center;
//   display: block;
//   cursor: pointer;
//   width: 100%;
//   padding: 8px;
//   color: #FFFFFF;
// }
