.latest-posts {
  padding: $inner-content-padding;
  width: 100%;
  @include mq($from: tablet) { max-width: 740px; }
  @include mq($from: desktop) { max-width: 1100px; }

  &__header {
    border-bottom: 2px solid $primary-color;
    margin-bottom: 26px;
    span {
      display: inline-block;
      padding: 7px 12px 4px;
      background-color: $primary-color;
      color: #FFFFFF;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      font-family: $heading-font-stack;
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    @include mq($from: tablet) {
      flex: 0 1 calc(33% - 1.365em);
    }
    margin-bottom: 24px;
    position: relative;
    &:hover {
      a:not(.card__topic) {
        color: $primary-color;
      }
    }

    &__thumbnail {
      height: 160px;
      position: relative;
      margin-bottom: 12px;
    }
    &__topic {
      position: absolute;
      bottom: 0;
      background-color: $color-black;
      color: #FFFFFF;
      font-size: 12px;
      font-family: $heading-font-stack;
      margin-right: 5px;
      font-weight: 500;
      display: block;
      padding: 3px 6px 4px;
      &:hover {
        background-color: $primary-color;
      }
    }
    &__image {
      display: block;
      border: 0;
      width: 100%;
      background-position: top center;
      height: 100%;
    }
  }
}

