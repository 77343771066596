//-----------------------------------------------------------------------------
// This file contains styles that are specific to the gallery page.
//-----------------------------------------------------------------------------
.video-page__single {
    aside.main-topbar {
        max-width: 1220px;
    }
}
.videos-page, .video-page {
    font-family: 'Open Sans', sans-serif;
    .site-logo {
        max-width: 136px !important; 
    }
    .ad-container-Video.Sidebar {
        margin-right:20px;
    }
    .main-sidebar {
        align-self: flex-start;
        flex: 1;
        max-width:325px;
        margin:0;
        margin-left: 20px !important;
    }
    #loading {
        margin:auto;
        font-size:76px;
        color: #999;
        position: fixed;
        top: calc(50% - 38px);
        left: calc(50% - 38px);
    }
    .main-header {
        margin-top: 10px;
        margin-bottom:40px;
        .wrapper {
            margin-left:0;
            padding-left:10px;
            max-width:100% !important;
        }
    } 
    #categories {
        padding-left: 20px;
        margin-top: -20px;
        padding-top: 20px;
        background: #efefef54;
        z-index: 0;
    }
    // .ads {
    //     min-height: 250px;
    //     background-color: #007fcf;
    // }
    .search-wrap{
        width: 50%;
        margin: 0 auto;
        max-width: 592px;
        margin-left: 200px;
        
        #video-search {
                width: 100%;
                position: relative;
            
            .video-search-input {
                float: left;
                width: 100%;
                border: 1px solid #c7c7c7;
                padding: 5px;
                height: 32px;
                border-radius: 5px;
                outline: none;
                color: #9DBFAF;
                font-size:13px;
                padding-left: 10px;
                border-radius:0;
            }
            
            .video-search-input:focus{
                color: #000;
            }
            
            .video-search-button {
                position: absolute;  
                right: -25px;
                width: 40px;
                height: 32px;
                border: 1px solid #c7c7c7;
                background: #f8f8f8;
                text-align: center;
                color: #979797;
                border-radius: 2px;
                cursor: pointer;
                font-size: 20px;
            }
        }
    }
    .content-wrapper {
        width: 100%;
        max-width: 100%;
    }

    main.featured {
        // flex: 1 0 auto;
        flex: 1;
        max-width: 930px;
        padding-left: 20px;
    }

    .cat-nav {
        // flex: 0 0 150px;
        flex: 1;
        max-width:150px;

        .sidebar-headline {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            color: #6b6b6b;
            font-weight:800;
        }

        .list {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            color: #000000;
        }

    }

    .video-main {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        flex-direction: row;
        margin-top: -20px;
        padding-top: 20px;
        justify-content: space-between;
    }

    .categories-list {
        li {
            list-style: none;
            margin: 20px 0;

            a {
                display: none;

                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.1px;
                color: #000000;
                &:hover {
                    font-weight:800;
                }
            }
        }
    }

    .top-videos {
        width: 100%;
        max-width: 960px;
        display: none;
        margin-bottom: 20px;
        h2 {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: -0.2px;
            color: #000000;
            margin-left:10px; 
            a:hover {
                color: black;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;

            .title {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.1px;
                color: #000000;
            }

            .channel,
            .stats {
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.1px;
                color: #666666;
            }

            .video-thumb {
                padding: 10px;

                img {
                    max-width: 230px;
                    width: 100%;
                }
            }
        }
    }

    
    .single-video {
        main.featured {
            max-width: 1400px;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
        }
        .main-bottombar {
            max-width:800px;
        }
        .cat-nav {
            display: none;
        }
        .video-holder {
            flex: 1 0 auto;
            max-width:880px;
            .title {
                font-size: 22px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 0.91;
                letter-spacing: -0.2px;
                color: #000000;
                max-width:800px;
            }
            .stats {
                margin-top: 15px;
                padding-bottom: 15px;
                border-bottom: solid 1px #dbdbdb;
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.11;
                letter-spacing: -0.2px;
                color: #666666;
            }
            #comments {
                .comment {
                    padding-top: 15px;
                    display: flex;
                    max-width:800px;
                    .avatar-holder {
                        flex: 0 0 45px;
                        margin-right: 40px;
                        height: 45px;
                        width: 45px;
                        img {
                            width: 100%;
                        }
                    }
                    .comment-holder {
                        flex: 1 0 50%;
                        .name {
                            font-size: 14px;
                            font-weight: bold;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.43;
                            letter-spacing: -0.1px;
                            color: #323232;
                            float: left;
                        }
                        .age {
                            font-size: 14px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.43;
                            letter-spacing: -0.1px;
                            color: #797979;
                            float: left;
                            margin-left: 15px;
                        }
                        .comment {
                            font-size: 14px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.57;
                            letter-spacing: -0.1px;
                            color: #000000;
                            clear: both;
                            padding-top: 0px;
                            max-width:729px;
                        }
                    }

                }
            }
            .channel {
                display: flex;
                padding-bottom: 15px;
                padding-top: 15px;
                border-bottom: solid 1px #dbdbdb;
                .channel-image {
                    flex: 0 0 60px;
                    width: 60px;
                    height: 60px;
                    margin-right:25px;
                    img {
                        width:100%;
                    }
                }
                .channel-info {
                    flex: 1 0 auto;
                    .channel-name {
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.43;
                        letter-spacing: -0.1px;
                        color: #323232;
                    } 
                    .channel-desc {
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.57;
                        letter-spacing: -0.1px;
                        color: #000000;
                        max-width:729px;
                    }
                }
            }
        }
        #main-sidebar {
            flex: 1 0 400px;
            width:400px !important;
            align-self: flex-start;
            .sidebar-headline {
                font-weight: bold;
                margin-left: 10px;
                padding-top: 0px;
            }
            .list {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: -0.1px;
                    color: #000000;
                }

                .channel,
                .stats {
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: -0.1px;
                    color: #666666;
                }

                .video-thumb {
                    padding: 10px;

                    img {
                        max-width: 320px;
                        width: 100%;
                    }
                }
            }

        }
    }
}