// -----------------------------------------------------------------------------
// This file contains all styles related to the article content of the site/application.
// -----------------------------------------------------------------------------

article {
  padding: $inner-content-padding;

  ol {
    list-style: decimal;
  }

  h1 {
    font: 700 28px/42px Roboto, Arial, sans-serif;
  }
  h3 {
    font: 400 22px/30px Roboto, Arial, sans-serif;
    margin: 27px 0 17px;
  }

  .article-body-container > p {
    font: 15px/26px Verdana, Geneva, sans-serif;
    margin-bottom: 26px;
  }

  p > a > img {
    max-width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
    > * :not(p:empty, h1, h2, h3, h4) {
    margin-left: auto;
    margin-right: auto;
  }
  > img, > iframe {
    margin: 0 auto 24px;
    display: block;
    width: 100%;
    max-width: 700px;

    @include mq($from:tablet) {
      height: 420px;

    }
  }
}
