// stacked and full width (height 225px) until tablet breakpoint (height: 280px)
.category-recent {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &__card a {
    width: 100%;
    height: 100%;
    flex: 0 0 225px;
    display: flex;
    min-height: 200px;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-bottom: 8px;
    &:hover .category-recent__image div {
      transform: scale(1.25);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    > div {
      width: 100%;
      height: 100%;
      @include transition(all, .25s, ease);
    }
  }
  &__content {
    width: 75%;
    color: #FFFFFF;
    z-index: 10;
    text-align: center;
     h3 {
      font-size: 21px;
      line-height: 26px;
    }
  }
}

@include mq($from:tablet) {
  .category-recent {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    &__card {
      flex: 0 0 49.75%;
      min-height: 280px;
      a {min-height: inherit}

    }
  }
}

@include mq($from:desktop) {
  .category-recent {
    &__card {
      min-height: 350px;
      a {min-height: inherit}

    }
    &__content {
      max-width: 85%;
      h3 {
      font-size: 28px;
      line-height: 36px;
    }
    }
  }
}

@include mq($from:desktopLg) {
  .category-recent {
    &__card {
      min-height: 400px;
      a {min-height: inherit}

    }
    &__content {
      max-width: 85%;
    h3 {
      font-size: 34px;
      line-height: 41px;
  }
  }
}
}
